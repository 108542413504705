import React from "react";
import image from "./bigbottle.jpg";
import "./App.css";

const App = () =>
	(
		<div className="App" >
			<img
				className="image"
				src={image}
			/>
			<div className="contact">
				<a href="tel:+7131300002" className="contactText">
					Telefone para contato: (71) 3130-0002
				</a>
			</div>

		</div>
	);


export default App;
